/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';

* {
  box-sizing: border-box;
}

.movable{
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.icon-50 {
  width: 50px;
}

.h-38px {
  height: 38px;
}
